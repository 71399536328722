import "./title.css";

function Title() {
    return (
      <div className="title">
        Sasha Frazier
      </div>
    );
  }
  
  export default Title;