import "./gallery.css";
import gift from './SampleSashaArt.jpg';
import eleven from './Eleven.jpg';
import eyeFlower from './EyeFlower.jpg';
import flowers1 from './Flowers1.jpg';
import fruit from './Fruit.jpg';
import gardenPeople from './GardenPeople.jpg';
import goddess from './Goddess.jpg';
import orangePeople from './OrangePeople.jpg';
import room from './Room.jpg';
import insta from "../instagram_green_logo.jpg"
import linkedIn from "../LinkedIn_logo.jpg";
import ceramic from "./Ceramic.jpg";


function Gallery() {
    const items = [
     {
        id: 1,
        src: room,
     },
     {
        id: 2,
        src: flowers1,
      },
      {
        id: 3,
        src: goddess,
      },
      {
        id: 4,
        src: fruit,
      },
      {
        id: 5,
        src: eleven,
      },
      {
        id: 6,
        src: gardenPeople,
      },
      {
        id: 7,
        src: eyeFlower,
      },
      {
        id: 8,
        src: orangePeople,
      },
      {
        id: 9,
        src: gift,
      },
      {
        id: 10,
        src: ceramic,
      },
   ];
    return (
        <>
        <div className="gallery">
            <div className="grid">
                {items.map(function(data) {
                    return (
                        <div key={data.id} className= "imgContainer">
                            <img src={data.src} alt={data.id} className= "piece">
                            </img>
                        </div>
                    )
                })}
            </div>
            <div className="footer">
                <br></br><br></br><br></br>
            <div className="socials">
                <a href="https://www.instagram.com/sashafrazierart/" target="_blank" rel="noreferrer">
                    <img alt="insta" src={insta} className= "social"></img>
                </a>

                <a href="https://www.linkedin.com/in/sasha-frazier-86523222b" target="_blank" rel="noreferrer">
                    <img alt="linkedIn" src={linkedIn} className= "social" ></img>
                </a>
                </div>
            </div>
            <br></br><br></br><br></br>
        </div>
        </>
    );
  }
  export default Gallery;
  
