import './Essays.css';
import '../divider.css';
import MediumLogo from '../medium_logo.png';
import flower from '../Art/flower.jpg';

function whyBlog() {
  return (
    <div>
    <h2>Solving for “Why”</h2>
        <div className="h-divider">
        <div className="shadow"></div>
        <div className="text2">
            <img alt="sashaLogo" src={flower} />
        </div>
    </div>
    <div className="writing">
        <div className="writingContent">
            <p>
            AI is efficient, almost magical for many of us. It can perform arduous tasks and answer difficult questions almost immediately, saving humans time and effort. It’s slowly becoming more of a commodity than a novelty as companies have found ways to incorporate it into almost every aspect of their businesses, including marketing. In some ways, this has proven useful with content generation, automated posting schedules, and data analysis. While these statistical aspects of marketing are important, they can only take a company’s brand vision so far. The missing component, that AI hasn’t been able to provide for companies, is their “why”. Their mission and reason for existence is much more around the “what” and the “how”. Purpose is always harder. When it comes to producing marketing strategies, AI will always function like a computer, unable to think abstractly the way humans can. AI lacks empathy, emotion, and creativity. The knowledge it provides is based on data and algorithms. On the other hand, humans can feel with their hearts and use judgment in their decisions when coming up with ideas and brainstorming together. Humans can take knowledge and apply it to new ideas. They have an ability to go beyond the given facts, and rather, apply the facts to create something new and beautiful. While AI shares knowledge that provides us with information, imagination empowers us to think beyond the given and go deeper than AI can. The power of imagination is what sets us apart from AI systems. Humans can see the invisible. AI can’t.
            </p>
            <p>
            Imagination is the ability to form one’s own ideas and dream up new concepts. It is the catalyst that fuels creativity and inspires innovation. Knowledge is limited to one’s understanding of static facts that imagination can build off and use to dream up new concepts. Where knowledge can simply be laid out for a person, imagination comes from within. Humans can make connections between different ideas and topics and generate innovative ideas, using the creativity that AI lacks. Users can obtain knowledge from generative AI in the form of direct answers to users’ questions. Knowledge extends only as far as possessing information. With imagination, users are able to visualize how they can utilize and apply it.
            </p>
            <p>
            As AI becomes more prevalent in all aspects of life, it is important to remember the unique qualities that make us human. Imagination and passion are unique to humans. AI is unable to empathize with others and connect the way humans can. Great marketing cannot be achieved with data and pattern recognition, alone. There must be a heart and soul to connect deeply with customers. AI can’t empathize with humans and understand their emotions or
            </p>
            <p>
            why they believe their endeavors are important. This shortcoming often leads those using AI for marketing astray. Since AI lacks heart and empathy, it often weakens one’s understanding of the value of human connection, taking their focus away from the real purpose of their company. This leads to a fixation on analytic strategy and negligence of wonder and creativity.
            </p>
            <p>
            Curiouser.AI encourages users to think for themselves. Curiouser.AI uses a new form of AI called Reflective AITM and unlike many asks the user questions, to guide the user through the problem-solving, thinking process. Curiouser.AI, much like Alice’s looking glass, is reflective and uses artificial intelligence to influence and teach the user. Instead of being guided by the user and following the user’s predetermined conversation in its response, Curiouser.AI guides the user towards finding and utilizing their soul or purpose. This helps the user understand successful marketing strategies, in line with their company’s mission, rather than spitting out an explanation limited to the scope of what the user provided them. Curiouser AI emphasizes human connection. In doing this, we acknowledge the importance of these human capabilities–empathy, creativity, and heart–as they are the true driving forces in finding and expressing one’s soul and purpose for their brand. They are the qualities that can inspire others and give a brand a voice and a soul beyond the bare bones of its product. Human imagination fosters curiosity within a company, allowing them to visualize and share their brand’s vision.
            </p>
            <p>
            Alice in Wonderland serves as a metaphor for the importance of imagination. When Alice follows the White Rabbit down the rabbit hole, she finds herself in a world that defies logic and is built on curiosity. This journey symbolizes the power imagination has to transport one beyond the limitations of logic and embrace innovation in the unknown. Alice’s adventure, and subsequent self-discovery, demonstrate how imagination functions as a catalyst for personal growth and discovery of our ultimate “why”.
            </p>
            <p>
            Lewis Carrol famously said, “The only weapon in the war against reality is imagination.” Perhaps he was hundreds of years ahead of his time.
            </p>
            <p>
            Sasha Frazier
            </p>
            <br></br>
            <div className="h-divider">
                <div className="shadow"></div>
            </div>
            <a href="https://medium.com/@curiouser.ai/solving-for-why-f42f219daeee" target="_blank" rel="noreferrer">
                <img alt="Medium Link" src={MediumLogo} className= "medium"></img>
            </a>
        </div>
            
        </div>
        </div>
  );
}

export default whyBlog;