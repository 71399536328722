import './navbar.css';
import React from 'react';
import {NavLink} from 'react-router-dom';
import { useEffect } from 'react';

function translateNavBar() {
  var pieces = document.getElementsByClassName("navContainer");
  for (let i = 0; i < pieces.length; i++) {
    console.log(window.location.pathname);
      if (window.location.pathname.startsWith("/Writing")) {
        pieces[i].style.transform = `translateX(-22.7px)`;
      }     
  }
}
function Navbar() {
  useEffect(() => {
        translateNavBar();
        return () => {
      };
    });
    return (
      <div className='navContainer'>
        <ul className={"navbar"}>
          <li className='navListItem'>
            <NavLink className = {"link-styles"} to="/">
            Home
            </NavLink>
          </li>
          <li className='navListItem'>
            <NavLink className = {"link-styles"} to="/Writing">Writing</NavLink>
          </li>
          <li className='navListItem'>
             <NavLink className = {"link-styles"} to="/art">Art</NavLink>
          </li>
          <li className='navListItem'>
             <NavLink className = {"link-styles"} to="/about">About</NavLink>
          </li>
        </ul>
      </div>
    );
  }
  
  export default Navbar;