import './App.css';
import About from "./about.js";
import Home from "./Home.js";
import Gallery from "./Art/gallery.js";
import Writing from "./Writing/Essays.js"
import SteveJobsBlog from './Writing/SteveJobsBlog.js';
import WhyBlog from './Writing/SolvingForWhy.js';
import OjaiEssay from './Writing/OjaiEssay.js';
import WarYea from './Writing/WarYea.js';
import Sidenav from './sidebar.js';
import NavBar from './navbar';
import {Route, Routes } from 'react-router-dom';
import FleetwoodEssay from './Writing/FleetwoodEssay.js';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path = "/about" 
            element = {<div className= "componentBlanket">
                          <NavBar />
                          <About />
                      </div>} />
        <Route path = "/" 
            element = {<div className= "componentBlanket">
                          <NavBar />
                          <Home />
                      </div>} />
        <Route path = "/art" 
            element = {<div className= "componentBlanket">
                          <NavBar />
                          <Gallery />
                      </div>} />
        <Route path = "/Writing"
            element = {<div className= "componentBlanket">
                        <div className= "pageWrapper">
                          <Sidenav/>
                          <div>
                            <NavBar />
                            <Writing />
                          </div>
                        </div> 
                      </div>} />   
        <Route path = "/Writing/Essays/CuriouserBlog/SteveJobs" 
            element = {<div className= "componentBlanket">
                        <div className= "pageWrapper">
                          <Sidenav/>
                          <div>
                            <NavBar />
                            <SteveJobsBlog />
                          </div>
                        </div>
                      </div>} />    
        <Route path = "/Writing/Essays/CuriouserBlog/SolvingForWhy" 
                        element = {<div className= "componentBlanket">
                       
                      <div className= "pageWrapper">
                         <Sidenav/>
                         <div>
                          <NavBar />
                          <WhyBlog />
                        </div>
                      </div>
                    </div>} /> 
        <Route path = "/Writing/Essays/VirtualCommunities" 
                        element = {<div className= "componentBlanket">
                        
                      <div className= "pageWrapper">
                        <Sidenav/>
                        <div>
                          <NavBar />
                          <OjaiEssay />
                        </div>
                      </div>
                    </div>} />  
        <Route path = "/Writing/Essays/Rebirth" 
                        element = {<div className= "componentBlanket">
                        
                      <div className= "pageWrapper">
                        <Sidenav/>
                        <div>
                          <NavBar />
                          <WarYea />
                        </div>
                      </div>
                    </div>} />  
        <Route path = "/Writing/Essays/FleetWood" 
                        element = {<div className= "componentBlanket">
                        
                      <div className= "pageWrapper">
                        <Sidenav/>
                        <div>
                          <NavBar />
                          <FleetwoodEssay />
                        </div>
                      </div>
                    </div>} />  

      </Routes>
      
    </div>
  );
}

export default App;
