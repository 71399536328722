import './Essays.css';
import '../divider.css';
import flower from '../Art/flower.jpg'
function Writing() {
  return (
    <div>
      <h2>Why I Write</h2>
      <div className="h-divider">
          <div className="shadow"></div>
          <div className="text2">
              <img alt="sashaLogo" src={flower} />
          </div>
      </div>
      <div className="writing">
          <div className='blogWrapper'> 
            <div className="writingContent">
              <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam vehicula dictum pulvinar. Phasellus quis risus volutpat, venenatis lacus sit amet, lacinia quam. Nunc at urna nisl. Donec vestibulum pretium orci at venenatis. Phasellus et iaculis sem. Donec tempus tortor non ligula finibus, ut gravida felis blandit. Vestibulum non hendrerit libero. Nulla facilisi.

              Integer porta magna purus, in laoreet massa tempus id. Fusce maximus finibus dapibus. Aenean consequat, mi quis condimentum pellentesque, massa eros vehicula velit, sed cursus dui quam vel mauris. Curabitur tempus laoreet risus. Nam tincidunt est sem, eu consequat diam molestie eget. Nulla suscipit suscipit nibh id molestie. Praesent mollis risus dui, vitae lobortis ex dignissim vitae.

              Phasellus eu mollis ipsum. Morbi cursus sodales orci, ut semper ante dignissim pretium. Integer id mi id orci bibendum lacinia sed in diam. Nunc luctus egestas leo ac pulvinar. Ut blandit molestie dolor eget auctor. Cras in imperdiet sem. Mauris dictum tellus ipsum, non porta justo pretium at. Pellentesque nec hendrerit felis. Aenean cursus non velit id elementum. Aliquam tristique neque sit amet sem convallis commodo. Donec ultricies lectus eget vulputate mollis. Vestibulum non velit id quam pretium sollicitudin a non felis. Nam porta neque volutpat, commodo dui vel, malesuada magna.

              Aenean ut lacus et augue ullamcorper blandit. Nunc quis tempor lectus. Quisque mollis dignissim eleifend. Praesent egestas imperdiet ante, sed rutrum nisl facilisis et. Aliquam vel feugiat diam. Maecenas eu congue neque. Integer porta volutpat nisi hendrerit mollis. Proin lobortis sit amet erat eu consectetur.

              Morbi justo nibh, feugiat id laoreet dapibus, tempus eu ipsum. Suspendisse placerat ornare ipsum, in mollis arcu pellentesque sit amet. Duis fermentum ut purus a efficitur. Nullam porta pharetra nibh sit amet facilisis. Fusce sollicitudin hendrerit mi, at euismod urna eleifend et. In lacus augue, fringilla vestibulum molestie quis, tincidunt eu urna. Morbi tincidunt vehicula odio, ac feugiat diam cursus in. Cras aliquam, mi quis blandit tempus, urna nibh varius eros, ut venenatis mi lacus vel felis.
              </p>
            </div>
          </div>
          
      </div>
      
    </div>
  );
}

export default Writing;