import './home.css';
import Title from './title';
import AnimatedFlower from './animatedFlower';
//import AnimatedBouqet from './animatedBouqet';
import { useEffect } from 'react';
import insta from "./instagram_green_logo.jpg"
import linkedIn from "./LinkedIn_logo.jpg";

const colors = ["#15310E", "#A73324", "#2B3070", "#B46B79", "650E19", 	"#BC551E", "#31613B", "#006838", "#9A9F55"];

function chaos() {
    var pieces = document.getElementsByClassName("p1");
    for (let i = 0; i < pieces.length; i++) {
        var x = Math.random() * 6000 - 3000;
        var y = Math.random() * 1000 - 500;
        var theta = Math.random() * 360 - 180;
        pieces[i].style.transition=`all, 10s ease-out`;
        pieces[i].style.transform = `translate(${x}px, ${y}px) rotate(${theta}deg)  `;
        pieces[i].style.fill = "currentColor";
        pieces[i].style.color = "Black";
    }
    
}
function revert() {
    var pieces = document.getElementsByClassName("p1");
    for (let i = 0; i < pieces.length; i++) {
        var randomNumber = Math.floor(Math.random() * 7);
        pieces[i].style.transition=`all, 3s ease-out`;
        pieces[i].style.transform = `translate(${0}px, ${0}px) rotate(${0}deg) `;
        pieces[i].style.color = colors[randomNumber];
    }

}

const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0.1
};

const observer = new IntersectionObserver(function(entries) {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        revert();
      } else {
        chaos();
      }
    });
}, options);

function Home() {
    useEffect(() => {
        chaos();
        var bottom = document.getElementById("check");
        observer.observe(bottom);
        return () => {
        revert();
      };
    });

  return (
    <div className="Home" id ="top">
        <div className="flowerTop"></div>
        <Title />
        <div className="flowerBottom"></div>
        <h1 className="quote"> In the midst of chaos... </h1>
        <div className = "vectorFlower">
        <AnimatedFlower/>
        </div>
        <h1 className="quote"> art is the achievement of stillness</h1>
        <h2 className="quote" id="check"> ― Saul Bellow</h2>
        <div className="socials">
            <a href="https://www.instagram.com/sashafrazierart/" target="_blank" rel="noreferrer">
                <img alt="insta" src={insta} className= "social"></img>
            </a>

            <a href="https://www.linkedin.com/in/sasha-frazier-86523222b" target="_blank" rel="noreferrer">
                <img alt="linkedIn" src={linkedIn} className= "social" ></img>
            </a>
        </div>
       
        
    </div>
    
  );
}

export default Home;