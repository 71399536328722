import React, { useState } from "react";
import './sidebar.css';
import {
Sidebar,
Menu,
MenuItem,
SubMenu,
} from "react-pro-sidebar";
import {Link} from 'react-router-dom';
import { CgChevronDoubleLeft } from "react-icons/cg";
import { CgChevronDoubleRight } from "react-icons/cg";
import { FaBookOpen } from "react-icons/fa";
import { MdLaptopChromebook } from "react-icons/md";


const Sidenav = () => {
    const [menuCollapse, setMenuCollapse] = useState(true)
    const menuIconClick = () => {
        menuCollapse ? setMenuCollapse(false) : setMenuCollapse(true);
    };
    return (
       <div> 
        <footer className="container">
            <div className= "test">
            <Sidebar collapsed={menuCollapse} collapsedWidth="45px"
            rootStyles={{
                backgroundColor: 'white',
                height: '100vh',
                width: '150px',
                minWidth: '150px',
                zIndex:'100', 
                fontFamily: 'Nanum Myeongjo',
                fontSize: '14px',
                fontWeight: 'bold',
                color: 'black',
                
            }}
            >
            <Menu closeOnClick="true"
            menuItemStyles={{
                subMenuContent: {
                    fontSize: '14px',
                    fontWeight: 'normal',
                    width: '150px',
                    padding: '0px',
                }
              }}>
                <div className="closemenu" onClick={menuIconClick}>
                    {menuCollapse ? <CgChevronDoubleRight size="20px" /> 
                    :<CgChevronDoubleLeft size="20px" />}
                    
                </div>
                <SubMenu 
                    label = {menuCollapse ? "":"Blog Posts"} 
                    icon = {<MdLaptopChromebook size = "25px" color = "#145335" />}>
                    <MenuItem component={<Link to="/Writing/Essays/CuriouserBlog/SteveJobs"></Link>} > 
                    Values Based Marketing
                    </MenuItem>
                    <MenuItem component ={<Link to="/Writing/Essays/CuriouserBlog/SolvingForWhy"></Link>} > 
                    Solving For Why
                    </MenuItem>
                </SubMenu>
                <SubMenu label = {menuCollapse ? "":"Essays"} icon = {<FaBookOpen color = "#145335" size = "25px" />}>
                    <MenuItem component ={<Link to="/Writing/Essays/VirtualCommunities"></Link>}>
                    Ojai Virtual Community
                    </MenuItem>
                    <MenuItem component ={<Link to="/Writing/Essays/Rebirth"></Link>}> 
                    Rebirth 
                    </MenuItem>
                    <MenuItem component ={<Link to="/Writing/Essays/Fleetwood"></Link>}> 
                    Fleetwood
                    </MenuItem>
                </SubMenu>
            </Menu>     
            </Sidebar> 
            </div>
        </footer>
    </div>
    );
}

export default Sidenav
