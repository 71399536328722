import './about.css';
import sasha from "./Sasha.jpg";
import insta from "./instagram_green_logo.jpg"
import linkedIn from "./LinkedIn_logo.jpg";

function About() {
  return (
    <div className="About">
      <h2>Sasha Frazier</h2>
      <div className="words">
        <img src={sasha} alt="Profile" className= "profile"></img>
        <div>
          
          <p className= "autobio">
          Senior at UC Berkeley majoring in Media Studies and minoring in Journalism. 
          Studying media theory and its applications to digital marketing. Currently 
          interning at Curiouser.AI, on marketing staff for FAST Cal, and media staff 
          for STAR Berkeley. Strong artistic background, interested in utilizing visual 
          appeal to strengthen audience’s engagement with a brand. Eager to learn about the 
          evolving industry of artificial intelligence and test AI’s capabilities to strengthen 
          and reinvent the process of digital marketing.
          </p>
          <div className="socials">
            <a href="https://www.instagram.com/sashafrazierart/" target="_blank" rel="noreferrer">
              <img alt="insta" src={insta} className= "social" ></img>
            </a>
            <a href="https://www.linkedin.com/in/sasha-frazier-86523222b" target="_blank" rel="noreferrer">
              <img alt="linkedIn" src={linkedIn} className= "social" ></img>
            </a>
          </div>
        </div>
      </div>
    </div>
    
  );
}

export default About;
